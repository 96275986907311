import Link from "next/link";
import { auth, signOutCompletely } from "../../utils/Authentication";
import { useEffect, useState } from "react";
import { t } from "i18next";
import Image from "next/image";
import i18next from "i18next";
import DarkMode from "../DarkMode";

export default function Header() {
  const [user, setUser] = useState(false);
  const [expanded, setExpanded] = useState(false);
  const [languageExpanded, setLanguageExpanded] = useState(false);
  const [selectedLanguage, setSelectedLanguage] = useState(i18next.language);

  const scrollToTryNow = () => {
    const element = document.getElementById("try-now");
    element?.scrollIntoView({ behavior: "smooth" });
  };

  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged((user) => {
      setUser(!!user);
    });
    return () => unsubscribe();
  }, []);

  const changeLanguage = (lng: string) => {
    i18next.changeLanguage(lng);
    setLanguageExpanded(false);
    setSelectedLanguage(i18next.language.split("-")[0]);
  };

  useEffect(() => {
    setSelectedLanguage(i18next.language.split("-")[0]);
  }, [i18next.language]);

  return (
    <header className="relative z-10 py-4 md:py-6 bg-gray-50">
      <div className="container px-4 mx-auto sm:px-6 lg:px-8">
        <div className="relative flex items-center justify-between">
          <Link href="/" title="" className="flex-shrink-0">
            <Image
              className="w-auto h-24"
              width={257}
              height={256}
              src="/logo.webp"
              alt="Professionalize It To Me"
            />
          </Link>
          <div className="flex xl:hidden">
            <button
              type="button"
              name="expand-menu"
              className="text-gray-900"
              onClick={() => setExpanded(!expanded)}
            >
              {expanded ? (
                <svg
                  className="w-7 h-7"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="M6 18L18 6M6 6l12 12"
                  />
                </svg>
              ) : (
                <svg
                  className="w-7 h-7"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="1.5"
                    d="M4 6h16M4 12h16M4 18h16"
                  />
                </svg>
              )}
            </button>
          </div>
          <div className="hidden lg:flex lg:items-center lg:justify-center lg:absolute md:inset-y-0 lg:left-36 lg:-translate-x-2/6 lg:space-x-8">
            <Link
              href="/pricing"
              className="text-base font-medium text-gray-900 transition-all duration-200 rounded focus:ring-1 focus:ring-gray-900 focus:ring-offset-2 hover:text-opacity-50"
            >
              {t("header.pricing")}
            </Link>
            <div className="relative">
              <button
                type="button"
                className="text-base flex font-medium text-gray-900 transition-all duration-200 rounded focus:ring-1 focus:ring-gray-900 focus:ring-offset-2 hover:text-opacity-50"
                onClick={() => setLanguageExpanded(!languageExpanded)}
              >
                {selectedLanguage.toUpperCase()}
                <svg
                  className="w-5 h-5 ml-2 -mr-1"
                  fill="currentColor"
                  viewBox="0 0 20 20"
                  aria-hidden="true"
                >
                  <path
                    fillRule="evenodd"
                    d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 011.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
                    clipRule="evenodd"
                  />
                </svg>
              </button>
              {languageExpanded && (
                <div className="absolute left-0 w-56 mt-2 bg-white border border-gray-200 rounded-md shadow-lg ring-1 ring-black ring-opacity-5">
                  <div className="py-1">
                    {["de", "en", "es", "fr", "it", "pt", "tr"].map((lng) => (
                      <Link
                        key={lng}
                        href="#"
                        className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100"
                        onClick={() => changeLanguage(lng)}
                      >
                        {t(`dropdown.${lng}`)}
                      </Link>
                    ))}
                  </div>
                </div>
              )}
            </div>
          </div>
          <div className="hidden xl:flex">
            <Link
              href="https://blog.professionalizeitto.me/"
              target="_blank"
              className="inline-flex mr-3 items-center justify-center px-6 py-3 text-base font-bold leading-7 text-white bg-teal-700 rounded hover:bg-gray-600 focus:ring-2 focus:ring-offset-2 focus:ring-gray-900"
            >
              {t("header.blog")}
            </Link>
            {!user ? (
              <>
                <Link
                  href="/sign-up"
                  className="inline-flex items-center justify-center px-6 py-3 text-base font-bold leading-7 text-black bg-gray-200 rounded hover:bg-gray-300 focus:ring-2 focus:ring-offset-2 focus:ring-gray-900"
                >
                  {t("sign-up.sign-up")}
                </Link>
                <Link
                  href="/sign-in"
                  className="ml-3 inline-flex items-center justify-center px-6 py-3 text-base font-bold leading-7 text-white bg-gray-900 rounded hover:bg-gray-600 focus:ring-2 focus:ring-offset-2 focus:ring-gray-900"
                >
                  {t("header.sign-in")}
                </Link>
              </>
            ) : (
              <>
                <Link
                  href="/dashboard"
                  className="ml-3 inline-flex items-center justify-center px-6 py-3 text-base font-bold leading-7 text-white bg-gray-900 rounded hover:bg-gray-600 focus:ring-2 focus:ring-offset-2 focus:ring-gray-900"
                >
                  {t("header.dashboard")}
                </Link>
                <a
                  href="#"
                  className="ml-2 inline-flex items-center justify-center px-6 py-3 text-base font-bold leading-7 text-white bg-gray-900 rounded hover:bg-gray-600 focus:ring-2 focus:ring-offset-2 focus:ring-gray-900"
                  onClick={signOutCompletely}
                >
                  {t("header.logout")}
                </a>
              </>
            )}
          </div>
        </div>
        {expanded && (
          <nav className="px-1 py-8">
            <div className="grid gap-y-7">
              <Link
                href="/pricing"
                className="lg:hidden text-base font-medium text-gray-900 transition-all duration-200 rounded focus:ring-1 focus:ring-gray-900 focus:ring-offset-2 hover:text-opacity-50"
              >
                {t("header.pricing")}
              </Link>
              <div className="relative lg:hidden">
                <button
                  type="button"
                  className="text-base flex font-medium text-gray-900 transition-all duration-200 rounded focus:ring-1 focus:ring-gray-900 focus:ring-offset-2 hover:text-opacity-50"
                  onClick={() => setLanguageExpanded(!languageExpanded)}
                >
                  {selectedLanguage.toUpperCase()}
                  <svg
                    className="w-5 h-5 ml-2 -mr-1"
                    fill="currentColor"
                    viewBox="0 0 20 20"
                    aria-hidden="true"
                  >
                    <path
                      fillRule="evenodd"
                      d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
                      clipRule="evenodd"
                    />
                  </svg>
                </button>
                {languageExpanded && (
                  <div className="absolute left-0 w-56 mt-2 bg-white border border-gray-200 rounded-md shadow-lg ring-1 ring-black ring-opacity-5">
                    <div className="py-1">
                      {["de", "en", "es", "fr", "it", "pt", "tr"].map((lng) => (
                        <Link
                          key={lng}
                          href="#"
                          className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100"
                          onClick={() => changeLanguage(lng)}
                        >
                          {t(`dropdown.${lng}`)}
                        </Link>
                      ))}
                    </div>
                  </div>
                )}
              </div>
              {!user ? (
                <>
                  <button
                    onClick={scrollToTryNow}
                    className="inline-flex items-center justify-center px-6 py-3 text-base font-bold leading-7 text-black bg-gray-200 rounded hover:bg-gray-300 focus:ring-2 focus:ring-offset-2 focus:ring-gray-900"
                  >
                    {t("header.try-for-free")}
                  </button>
                  <Link
                    href="/sign-in"
                    className="ml-3 inline-flex items-center justify-center px-6 py-3 text-base font-bold leading-7 text-white bg-gray-900 rounded hover:bg-gray-600 focus:ring-2 focus:ring-offset-2 focus:ring-gray-900"
                  >
                    {t("header.sign-in")}
                  </Link>
                </>
              ) : (
                <>
                  <Link
                    href="/dashboard"
                    className="ml-3 inline-flex items-center justify-center px-6 py-3 text-base font-bold leading-7 text-white bg-gray-900 rounded hover:bg-gray-600 focus:ring-2 focus:ring-offset-2 focus:ring-gray-900"
                  >
                    {t("header.dashboard")}
                  </Link>
                  <a
                    href="#"
                    className="ml-3 inline-flex items-center justify-center px-6 py-3 text-base font-bold leading-7 text-white bg-gray-900 rounded hover:bg-gray-600 focus:ring-2 focus:ring-offset-2 focus:ring-gray-900"
                    onClick={signOutCompletely}
                  >
                    {t("header.logout")}
                  </a>
                </>
              )}
            </div>
          </nav>
        )}
      </div>
    </header>
  );
}
