import { Analytics } from "@vercel/analytics/react";
import type { AppProps } from "next/app";
import "../styles/globals.css";
import { app } from "../initFirebase";
import i18next from "i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import { useEffect, useState } from "react";
import Header from "../components/Core/Header";
import Footer from "../components/Core/Footer";
import Head from "next/head";
import { Client } from "appwrite";

app;
function MyApp({ Component, pageProps }: AppProps) {
  i18next.use(LanguageDetector).init({
    fallbackLng: "en",
    resources: {
      de: {
        translation: require("../public/locales/de/translation.json"),
      },
      en: {
        translation: require("../public/locales/en/translation.json"),
      },
      es: {
        translation: require("../public/locales/es/translation.json"),
      },
      fr: {
        translation: require("../public/locales/fr/translation.json"),
      },
      it: {
        translation: require("../public/locales/it/translation.json"),
      },
      pt: {
        translation: require("../public/locales/pt/translation.json"),
      },
      tr: {
        translation: require("../public/locales/tr/translation.json"),
      },
    },
  });

  const [initialRenderComplete, setInitialRenderComplete] =
    useState<boolean>(false);

  useEffect(() => {
    setInitialRenderComplete(true);
  }, []);

  if (!initialRenderComplete) return <></>;

  const client = new Client();

  client
    .setEndpoint("https://pitm.deploy.emreisik.dev/v1")
    .setProject("66af5d960035099a03ef");

  return (
    <>
      <Head>
        <title>
          Professionalize It To Me - GPT4 Professional Message Generator
        </title>
      </Head>
      <Header />
      <Component {...pageProps} />
      <Analytics />
      <Footer />
    </>
  );
}

export default MyApp;
