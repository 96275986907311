import { getAuth, signInWithPopup, signOut, signInWit } from "firebase/auth";
import { GoogleAuthProvider, User, GithubAuthProvider } from "firebase/auth";
import Router from "next/router";

export const auth = getAuth();
export const googleProvider = new GoogleAuthProvider();
export const githubProvider = new GithubAuthProvider();
const router = Router;

googleProvider.addScope('https://www.googleapis.com/auth/userinfo.email');
githubProvider.addScope('repo');
export const signInWithGoogle = async () => {
    try {
      signInWithPopup(auth, googleProvider)
        .then((result) => {
          var credential = firebase.auth.GoogleAuthProvider.credential(
            result.getAuthResponse().id_token);

        }).catch((error) => {
          return false;
        });
    } catch (error) {
      console.log(error)
    }
  }

export const signInWithGithub = async () => {
    try {
      signInWithPopup(auth, githubProvider)
        .then((result) => {
          var credential = firebase.auth.GithubAuthProvider.credential(
            result.getAuthResponse().id_token);

        }).catch((error) => {
          return false;
        });
    } catch (error) {
      console.log(error)
    }
  }




export const signOutCompletely = async () => {
    try {
      signOut(auth).then(() => {
        router.push('/')
        return true;
      })
    } catch (error) {
      router.push('/')
      console.log(error)
      return false;
    }
  }