import React, { useState } from 'react';
import Image from 'next/image';
import Link from 'next/link';
import { Toaster, toast } from "react-hot-toast";
import {t} from 'i18next';

const Footer = () => {        

    const [email, setEmail] = useState('');

    const handleEmailSubmit = (e: { preventDefault: () => void; }) => {
        e.preventDefault();

        const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        if (!re.test(String(email).toLowerCase())) {
           toast.error(t('toaster.validate-email'));
            return;
        }

        const data = {
            email: email
        }

        toast.loading(t('toaster.please-wait'));

        fetch('/api/subscribe-newsletter', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(data)
        })
        .then(res => res.json())
        .then(data => {
            toast.dismiss();
            toast.success(t('toaster.succesful-sign-up'));
            console.log(data)
        })
        .catch(err => {
            toast.error(t('toaster.error-occurred'));
            console.log(err)
        })
    }
    
    return (
        <footer className="py-12 bg-white sm:pt-16 lg:pt-20">
    <div className="px-4 mx-auto max-w-7xl sm:px-6 lg:px-8">

        <div className="grid grid-cols-2 mt-16 sm:grid-cols-3 gap-y-16 lg:grid-cols-5 gap-x-16">
            <div>
                <h2 className="text-sm font-bold tracking-widest text-gray-900 uppercase font-pj">{t('footer.help')}</h2>

                <ul className="mt-8 space-y-5">
                    <li>
                        <Link href="/privacy" className="inline-flex text-sm font-normal text-gray-900 transition-all duration-300 transform font-pj hover:text-gray-600 hover:translate-x-1"> {t('footer.privacy-policy')} </Link>
                    </li>
                    <li>
                        <Link href="/terms" className="inline-flex text-sm font-normal text-gray-900 transition-all duration-300 transform font-pj hover:text-gray-600 hover:translate-x-1"> {t('footer.terms-of-service')} </Link>
                    </li>
                    <li>
                        <Link href="mailto:support@professionalizeitto.me" className="inline-flex text-sm font-normal text-gray-900 transition-all duration-300 transform font-pj hover:text-gray-600 hover:translate-x-1"> {t('footer.support')} </Link>
                    </li>
                </ul>
            </div>

            <div className="col-span-2 sm:col-span-1">
                <h2 className="text-sm font-bold tracking-widest text-gray-900 uppercase font-pj">{t('footer.resources')}</h2>

                <ul className="mt-8 space-y-5">
                    <li>
                        <Link href="/cover-letter" title="" className="inline-flex text-sm font-normal text-gray-900 transition-all duration-300 transform font-pj hover:text-gray-600 hover:translate-x-1"> {t('footer.cover-letter-generator')} </Link>
                    </li>

                    <li>
                        <Link href="/formula-generator" title="" className="inline-flex text-sm font-normal text-gray-900 transition-all duration-300 transform font-pj hover:text-gray-600 hover:translate-x-1"> {t('footer.excel-formula-generator')} </Link>
                    </li>
                </ul>
            </div>

            <div className="col-span-2 sm:col-span-3 xl:pl-20">
                <h2 className="text-sm font-bold tracking-widest text-gray-900 uppercase font-pj">{t('footer.subscribe-to-newsletter')}</h2>

                <div className="relative mt-8">
                    <div className="absolute -inset-2">
                        <div className="w-full h-full mx-auto opacity-30 blur-lg filter" style={{background: "linear-gradient(90deg, #44ff9a -0.55%, #44b0ff 22.86%, #8b44ff 48.36%, #ff6644 73.33%, #ebff70 99.34%)"}}></div>
                    </div>

                    <form className="relative">
                        <div className="flex">
                            <div className="flex-1">
                                <input type="email" onChange={(e) => setEmail(e.target.value)} placeholder={t('footer.enter-your-email') ?? ''} className="block w-full px-4 py-4 text-base text-gray-900 placeholder-gray-600 bg-white border-gray-300 focus:ring-gray-900 focus:border-gray-900 rounded-l-xl font-pj caret-gray-900" required />
                            </div>

                            <button onClick={handleEmailSubmit} type="submit" className="px-10 py-4 text-base font-bold text-white transition-all duration-200 bg-gray-900 border border-transparent sm:px-16 focus:ring-2 focus:ring-offset-2 focus:ring-gray-900 rounded-r-xl font-pj focus:outline-none">{t('footer.join')}</button>
                        </div>
                    </form>
                </div>

                <div className="grid grid-cols-1 mt-8 gap-y-8 sm:grid-cols-1 sm:gap-x-16">
                    <div>
                        <h2 className="text-sm font-bold tracking-widest text-gray-900 uppercase font-pj">{t('footer.email-us')}</h2>
                        <p className="mt-2.5 text-xl font-pj text-gray-900 font-bold">
                            <Link href="mailto:support@professionalizeitto.me" title=""> support@professionalizeitto.me </Link>
                        </p>
                    </div>
                </div>
            </div>
        </div>

        <hr className="mt-16 border-gray-200" />

        <div className="mt-8 sm:flex sm:items-center sm:justify-between">
            <p className="mt-8 text-sm font-normal text-gray-600 font-pj sm:order-1 sm:mt-0">© Copyright 2023, {t('footer.all-rights-reserved')}</p>
        </div>
    </div>
    </footer>
    )
}
export default Footer;